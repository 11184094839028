import React from 'react';
import { makeStyles } from '@mui/styles';
import FacebookIcon from 'assets/svgs/Facebook';
import TwitterIcon from 'assets/svgs/Twitter';
import LinkedinIcon from 'assets/svgs/Linkedin';
import useModal from 'utils/hooks/useModal';
import ModalRGPD from 'data/components/Modals/modalRGPD';
import Modal from 'data/components/Modals/Modal';
import ModalCGU from 'data/components/Modals/modalCGU';
import YoutubeIcon from 'assets/svgs/Youtube';
import Text from 'data/components/Text';

const Footer = props => {
    const classes = useStyles();
    const [RGPDIsOpen, toggleRGPD] = useModal();
    const [CGUIsOpen, toggleCGU] = useModal();

    return (
        <div></div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        zIndex: 1300,
        position: 'absolute',
        backgroundColor: '#333',
        bottom: -120,
        width: '100%',
        padding: 50,
        height: 100,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    containerExpanded: {
        zIndex: 9999,
        position: 'absolute',
        backgroundColor: '#333',
        bottom: -120,
        width: '100%',
        padding: 50,
        height: 100,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    copyrights: {
        display: 'flex',
        flexDirection: 'column',
    },
    RGPDCGU: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    socialMedia: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: 150,
    },
    copyrightsText: {
        color: 'white',
        fontSize: 20,
    },
    allRightsReserved: {
        color: 'white',
        fontSize: 14,
    },
    text: {
        color: 'white',
    },
    cornerRightText: {
        color: 'white',
        width: 150,
    },
}));

export default Footer;
