import React from 'react';
import Grid from '../Grid';
import { makeStyles } from '@mui/styles';
import LeafIcon from '../../../assets/svgs/Leaf';
import AutoConsoBarChart from '../../../navigation/screens/Stats/Charts/BarChart';
import RT2012PieChart from '../../../navigation/screens/Stats/Charts/PieChart';
import { selectGeneratorsIds } from '../../../store/generators/generatorSlice';
import { useSelector } from 'react-redux';
import Text from '../Text';
import SchemeRightArrow from '../../../assets/svgs/SchemeRightArrow';
import SchemeLeftArrow from '../../../assets/svgs/SchemeLeftArrow';
import SchemePanel from '../../../assets/svgs/SchemePanel';
import SchemeHouse from '../../../assets/svgs/SchemeHouse';
import SchemeTower from '../../../assets/svgs/SchemeTower';
import SchemeDataLabel from '../../../assets/svgs/SchemeDataLabel';
import Tooltip from '../Tooltip';
import Box from '@mui/material/Box';
import SvgScheme from '../Scheme/svgScheme';

const StatsChartsContainer = ({
    filteredRT2012,
    filteredConsoProdStats,
    sidePanelIsOpen,
    sidePanelContainerRef,
    co2,
    filter,
    homeNetworkStats,
}) => {
    const classes = useStyles(sidePanelIsOpen);
    const generators = useSelector(selectGeneratorsIds);

    return (
        <Grid container justifyContent={'center'}>
            <Grid
                container
                item
                justifyContent="center"
                xs={10}
                className={classes.container}
            >
                {generators.length >= 1 && filteredConsoProdStats.length > 0 && (
                    <>
                        <div className={classes.co2}>
                            <Tooltip title={'CO₂'}>
                                <div className={classes.co2Icon}>
                                    <LeafIcon
                                        color={'#4CAF50'}
                                        width={175}
                                        height={250}
                                    />
                                </div>
                            </Tooltip>
                            <Text className={classes.co2Text}>
                                {co2} kg de CO₂ économisé
                            </Text>
                        </div>
                        <div className={classes.eco}>
                            <Tooltip
                                title={
                                    "Le taux d’autoproduction est le rapport entre la consommation d’électricité consommée grâce à vos panneaux photovoltaïques et la consommation totale d’électricité. C’est donc un indicateur de l’autonomie d’un logement par rapport au réseau électrique. Plus ce taux est important, moins le logement utilise de l'électricité du réseau public"
                                }
                            >
                                <h1 className={classes.ecoPercent}>
                                    {Math.round(
                                        (homeNetworkStats.autoConsoValue /
                                            homeNetworkStats.consoFoyer) *
                                            100 || 0,
                                    )}
                                    %
                                </h1>
                            </Tooltip>

                            <Text className={classes.ecoText}>d'autonomie</Text>
                        </div>
                        <div className={classes.schemeContainer}>
                            <SvgScheme
                                homeNetworkStats={homeNetworkStats}
                                width={150}
                                height={150}
                                arrowWidth={60}
                                arrowColor={'#00988E'}
                            />
                        </div>
                    </>
                )}
            </Grid>
            <Grid
                container
                item
                justifyContent="center"
                xs={12}
                className={classes.chartsContainer}
            >
                {filteredConsoProdStats.length > 0 ? (
                    <AutoConsoBarChart
                        consoProdStats={filteredConsoProdStats}
                        width={'50%'}
                        height={300}
                        margin={{ right: 0 }}
                        autoconso
                        filter={filter}
                    />
                ) : (
                    <Text
                        style={{
                            fontWeight: 800,
                            color: '#00988E',
                            fontSize: 22,
                        }}
                    >
                        Aucune donnée à afficher
                    </Text>
                )}
                {filteredConsoProdStats.length > 0 && (
                    <RT2012PieChart RT2012={filteredRT2012} />
                )}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        marginTop: 24,
        marginRight: 24,
        padding: 8,
    },
    co2: {
        display: 'flex',
        flexDirection: 'column',
        width: '30%',
        marginTop: 24,
    },
    eco: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
        justifyContent: 'center',
    },
    ecoPercent: {
        display: 'flex',
        alignSelf: 'center',
        marginBottom: 12,
        fontSize: 115,
        color: theme.palette.primary.main,
    },
    ecoText: {
        textAlign: 'center',
        fontSize: 22,
        fontWeight: 800,
        color: theme.palette.text.primary,
    },
    co2Icon: {
        display: 'flex',
        alignSelf: 'center',
        marginBottom: 12,
    },
    co2Text: {
        textAlign: 'center',
        fontSize: 22,
        fontWeight: 800,
        color: theme.palette.text.primary,
    },
    autoConso: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    schemeContainer: {
        display: 'flex',
        width: '50%',
        justifyContent: 'center',
        marginTop: 24,
    },
    scheme: {
        display: 'flex',
        alignSelf: 'center',
    },
    chartsContainer: {
        display: 'flex',
        marginTop: 24,
        marginLeft: 24,
        padding: 8,
    },
    schemeLayer: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        height: 250,
        justifyContent: 'space-evenly',
        marginLeft: 4,
        marginRight: 4,
    },
    arrowLayer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignContent: 'center',
    },
}));

export default StatsChartsContainer;
