import {
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Text,
    Tooltip,
} from 'recharts';
import React from 'react';

const RT2012PieChart = props => {
    const COLORS = ['#FA4616', '#279989', '#DA291C', '#888B8D'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <Text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(1)}%`}
            </Text>
        );
    };

    return (
        <ResponsiveContainer width={'30%'} height={300}>
            <PieChart>
                <Pie
                    data={props.RT2012}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={110}
                    fill="#8884d8"
                >
                    {props.RT2012.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                        />
                    ))}
                </Pie>
                <Tooltip
                    formatter={(value, name, props) => {
                        return (
                            <p>{Math.round((value * 100) / 1000) / 100} kWh</p>
                        );
                    }}
                />
                <Legend layout={'horizontal'} verticalAlign={'bottom'} />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default RT2012PieChart;
