import React, { useEffect, useState } from 'react';
import ProductsGroup from './ProductsGroup/ProductsGroup';
import Grid from 'data/components/Grid';
import { makeStyles } from '@mui/styles';
import DashboardProductsGroup from './ProductsGroup/DashboardProductsGroup';
import EcoTipsWidget from './ProductsGroup/EcoTipsWidget';
import ConsoProdWidget from './ProductsGroup/ConsoProdWidget';
import * as R from 'ramda';

export default function ProductsList({ groups, dashboard, filter }) {
    const classes = useStyles();
    const [firstGroup, setFirstGroup] = useState(['switches', []]);

    useEffect(() => {
        const filteredGroups = R.reject(R.isEmpty, groups);

        setFirstGroup(Object.entries(filteredGroups)[0]);

        delete groups[Object.keys(filteredGroups)[0]];
    }, []);

    return (
        <Grid container spacing={6} className={classes.container}>
            {dashboard && <ConsoProdWidget />}
            {dashboard && (
                <Grid
                    container
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 500,
                    }}
                    item
                    xs={6}
                >
                    <EcoTipsWidget />
                    <DashboardProductsGroup
                        key={firstGroup[0]}
                        group={firstGroup[0]}
                        productIds={firstGroup[1]}
                    />
                </Grid>
            )}
            {Object.entries(groups).reduce((acc, [group, productIds]) => {
                if (productIds.length) {
                    dashboard && acc !== 0
                        ? acc.push(
                              <DashboardProductsGroup
                                  key={group}
                                  group={group}
                                  productIds={productIds}
                              />,
                          )
                        : acc.push(
                              <ProductsGroup
                                  key={group}
                                  group={group}
                                  productIds={productIds}
                                  filter={filter}
                              />,
                          );
                }
                return acc;
            }, [])}
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        padding: 24,
    },
}));
