import Paper from '../../Paper';
import Grid from '../../Grid';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Text from '../../Text';
import Box from '@mui/material/Box';
import { useSelector, shallowEqual } from 'react-redux';
import { productsSelectors } from '../../../../store/products/selectors';
import { selectGeneratorsIds } from '../../../../store/generators/generatorSlice';
import { selectHomeNetworkStats } from '../../../../store/stats/statsSlice';
import toArray from 'yup/es/util/toArray';
import AutoConsoBarChart from '../../../../navigation/screens/Stats/Charts/BarChart';

export default function ConsoProdWidget() {
    const classes = useStyles();
    const [consoStats, setConsoStats] = useState([]);
    const [prodStats, setProdStats] = useState([]);
    const [autoConsoStats, setAutoConsoStats] = useState([]);

    const clamps = useSelector(productsSelectors.selectClamps, shallowEqual);
    const generators = useSelector(selectGeneratorsIds);

    const homeNetworkStats = useSelector(selectHomeNetworkStats);

    useEffect(() => {
        if (homeNetworkStats.energy_detail) {
            setConsoStats(
                toArray(Object.values(homeNetworkStats.energy_detail.conso)),
            );
            setProdStats(
                toArray(Object.values(homeNetworkStats.energy_detail.prod)),
            );
        }
        if (homeNetworkStats.autoconso_detail) {
            setAutoConsoStats(
                toArray(Object.values(homeNetworkStats.autoconso_detail)),
            );
        }
    }, []);

    if (!Array.isArray(consoStats) || !Array.isArray(prodStats)) {
        return null;
    }

    const newConsoStats = consoStats.map((stat, i) =>
        Object.assign({
            wh: stat.watth,
            timestamp: stat.timestamp,
            step: stat.step,
            date: stat.date,
        }),
    );

    const consoProdStats = newConsoStats.map((stat, i) =>
        Object.assign({}, stat, prodStats[i], autoConsoStats[i]),
    );

    consoProdStats.sort((a, b) => a.timestamp - b.timestamp);

    let consoInstantData = 0;
    let prodInstantData = 0;

    let consoClamps = {};
    let prodClamps = {};

    Object.values(clamps).map(clamp => {
        let module = clamp.product_identifier;
        let code = clamp.code;
        if (clamp.conso_prod_type === 'prod') {
            prodClamps[`${module}_${code}`] = clamp.instant;
        } else if (clamp.conso_prod_type === 'conso') {
            consoClamps[`${module}_${code}`] = clamp.instant;
        }
    });

    consoInstantData =
        Object.keys(consoClamps).length &&
        Object.values(consoClamps).reduce((a, b) => a + b);
    prodInstantData =
        Object.keys(prodClamps).length &&
        Object.values(prodClamps).reduce((a, b) => a + b);

    return (
        <Grid
            container
            style={{ justifyContent: 'center', height: 500 }}
            item
            xs={6}
        >
            <Paper elevation={5} className={classes.container}>
                <AutoConsoBarChart
                    consoProdStats={consoProdStats}
                    width={'100%'}
                    height={250}
                    margin={{ top: 12, right: 48 }}
                    autoconso
                    filter={'hours'}
                />
                <Box className={classes.box}>
                    <Box>
                        <Text className={classes.consoData}>
                            {!consoInstantData ? '- ' : consoInstantData} W
                        </Text>
                        <Text className={classes.text}>Consommation</Text>
                    </Box>
                    {generators.length >= 1 && (
                        <Box>
                            <Text className={classes.prodData}>
                                {!prodInstantData ? '- ' : prodInstantData} W
                            </Text>
                            <Text className={classes.text}>Production</Text>
                        </Box>
                    )}
                </Box>
            </Paper>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 700,
        gap: 12,
        padding: 4,
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        padding: 8,
        width: '80%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        color: theme.palette.primary.main,
        width: 380,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 22,
    },
    consoData: {
        fontSize: 32,
        width: 380,
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#2196F3',
    },
    prodData: {
        fontSize: 28,
        width: 380,
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#4CAF50',
    },
}));
