import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';
import MUITableCell from '@mui/material/TableCell';
import CloseIcon from 'assets/svgs/Close';
import Button from 'data/components/Button';
import useModal from 'utils/hooks/useModal';
import Modal from 'data/components/Modals/Modal';
import PlanningModalForm from 'data/components/Modals/PlanningModals/PlanningModalForm';
import PlanningCell from './PlanningCell';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import UpArrow from 'assets/svgs/ArrowUpIcon';
import DownArrow from 'assets/svgs/ArrowDownIcon';
import { useSelector } from 'react-redux';
import { planningEventsSelectors } from '../../../../store/planningEvents/planningEventsSlice';
import { withStyles } from '@mui/styles';

const renderEvent = (event, setSelectedItem, toggleModal) => (
    <Button
        key={event.product_identifier}
        onClick={() => {
            setSelectedItem(event);
            toggleModal();
        }}
    >
        <PlanningCell planningEvent={event} />
    </Button>
);

const renderEvents = (
    planningByHour,
    collapsable,
    toggleModal,
    hour,
    rowsOpen,
    column,
    setSelectedItem,
) => {
    const eventsPerDay = planningByHour[hour].reduce(
        (acc, event) => {
            const dayIndex = event.num_of_day;

            acc[dayIndex].push(event);

            return acc;
        },
        {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
        },
    );

    return (
        <StyledTableCell component="th" sx={{ padding: 0 }}>
            {eventsPerDay[column].map((event, index) => {
                if (index > 1) return;

                return renderEvent(event, setSelectedItem, toggleModal);
            })}

            {collapsable && (
                <Collapse
                    in={rowsOpen.includes(hour)}
                    timeout="auto"
                    unmountOnExit
                >
                    {eventsPerDay[column].map((event, index) => {
                        if (index < 2) return;

                        return renderEvent(event, setSelectedItem, toggleModal);
                    })}
                </Collapse>
            )}
        </StyledTableCell>
    );
};

const PlanningTable = props => {
    const classes = useStyles();
    const [modalIsOpen, toggleModal] = useModal();
    const [rowsOpen, setRowsOpen] = useState([]);
    const planningEvents = useSelector(state =>
        planningEventsSelectors.selectAll(state),
    );
    const [selectedItem, setSelectedItem] = useState({});

    const openEntryForm = () => {
        toggleModal();
    };

    const filterArray = (rowsOpen, hour) => {
        const newArray = rowsOpen.filter(item => item !== hour);
        setRowsOpen(newArray);
    };

    useEffect(() => {
        setSelectedItem({});
    }, []);

    const planningHours = [
        ...new Set(planningEvents.map(event => event.event_time)),
    ];

    const planningByHours = planningEvents.reduce((acc, it) => {
        if (!acc[it.event_time]) {
            acc[it.event_time] = [];
        }

        acc[it.event_time].push(it);

        return acc;
    }, []);

    const columns = [
        { id: '0', label: 'Lundi' },
        { id: '1', label: 'Mardi' },
        { id: '2', label: 'Mercredi' },
        { id: '3', label: 'Jeudi' },
        { id: '4', label: 'Vendredi' },
        { id: '5', label: 'Samedi' },
        { id: '6', label: 'Dimanche' },
    ];

    return (
        <div style={{ margin: 4 }}>
            <TableContainer
                sx={
                    props.headerExpanded
                        ? classes.tableContainerExpanded
                        : classes.tableContainer
                }
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                key={7}
                                align={'left'}
                                variant={'head'}
                            >
                                Horaires
                            </StyledTableCell>
                            {columns.map(column => (
                                <StyledTableCell
                                    key={column.id}
                                    align={'left'}
                                    variant={'head'}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {planningHours.map(hour => {
                            let collapsable = false;
                            let eventsPerDay = {
                                0: 0,
                                1: 0,
                                2: 0,
                                3: 0,
                                4: 0,
                                5: 0,
                                6: 0,
                            };

                            planningByHours[hour].forEach(event => {
                                eventsPerDay[event.num_of_day]++;

                                if (eventsPerDay[event.num_of_day] > 2) {
                                    collapsable = true;
                                }
                            });

                            return (
                                <TableRow>
                                    <StyledTableCell>
                                        {hour}

                                        {collapsable && (
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => {
                                                    rowsOpen.includes(hour)
                                                        ? filterArray(
                                                              rowsOpen,
                                                              hour,
                                                          )
                                                        : setRowsOpen([
                                                              ...rowsOpen,
                                                              hour,
                                                          ]);
                                                }}
                                                sx={{ padding: 2 }}
                                            >
                                                {rowsOpen.includes(hour) ? (
                                                    <UpArrow
                                                        color={'black'}
                                                        width={28}
                                                        height={28}
                                                    />
                                                ) : (
                                                    <DownArrow
                                                        color={'black'}
                                                        width={28}
                                                        height={28}
                                                    />
                                                )}
                                            </IconButton>
                                        )}
                                    </StyledTableCell>

                                    {columns.map(column => {
                                        return renderEvents(
                                            planningByHours,
                                            collapsable,
                                            toggleModal,
                                            hour,
                                            rowsOpen,
                                            column.id,
                                            setSelectedItem,
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*<div*/}
            {/*    className={*/}
            {/*        props.headerExpanded*/}
            {/*            ? classes.addButtonContainer*/}
            {/*            : classes.addButtonContainerExpanded*/}
            {/*    }*/}
            {/*>*/}
            {/*    <Button*/}
            {/*        style={{*/}
            {/*            transform: 'rotate(45deg)',*/}
            {/*        }}*/}
            {/*        onClick={() => {*/}
            {/*            setSelectedItem({});*/}
            {/*            openEntryForm();*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <CloseIcon width={30} />*/}
            {/*    </Button>*/}
            {/*</div>*/}

            <Modal
                open={modalIsOpen}
                closeFunction={toggleModal}
                submit={() => {}}
                modalForm
            >
                <PlanningModalForm selectedItem={selectedItem} />
            </Modal>
        </div>
    );
};

const StyledTableCell = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        width: 200,
        height: 90,
        fontSize: 18,
    },
}))(MUITableCell);

const useStyles = makeStyles(theme => ({
    addButtonContainer: {
        position: 'fixed',
        right: 20,
        bottom: 20,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 45,
        backgroundColor: '#00988E',
        width: 60,
        height: 60,
        zIndex: 99999,
    },
    addButtonContainerExpanded: {
        position: 'fixed',
        right: 20,
        bottom: 120,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 45,
        backgroundColor: '#00988E',
        width: 60,
        height: 60,
        zIndex: 99999,
    },
    hours: {
        display: 'flex',
        marginTop: 24,
        fontSize: 21,
    },
    tableContainerExpanded: {
        width: '100%',
        maxHeight: 500,
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            width: 0,
        },
        scrollbarColor: 'transparent transparent',
    },
    tableContainer: {
        maxHeight: 700,
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            width: 0,
        },
        scrollbarColor: 'transparent transparent',
    },
}));

export default PlanningTable;
